<template>
    <div id="app_center">
        <div class="join_us">
            <div class="join_us_bg"></div>
            <div class="join_header"></div>
            <div class="join_content" style="margin-bottom: 6rem">
                <div class="profile_main">
                    <div class="share_img">
                        <img :src="draw_info && draw_info.event_photo_url || ''">
                    </div>
                    <div class="profile_card share_card">
                        <h2 class="fy_bolder" style="text-align: center;">{{ draw_info && draw_info.event_name || ''}}</h2>
                        <div class="profile_info">
                            {{ draw_info && draw_info.event_description || ''}}
                        </div>
                        <div class="profile_btn">
                            <a @click="joinNotify" v-if="draw_info.event_status == 1" :class="{'no_start': has_set_notified, 'start': !has_set_notified }" class="start" href="javascript:;">
                                <span>{{ has_set_notified ? "We’ll notify you to join" : event_status }}</span>
                            </a>
                            <a @click="joinDraw" v-else :class="{'no_start': draw_info.event_status != 2 || has_joined, 'start': draw_info.event_status == 2 }" href="javascript:;">
                                <span>{{ event_status }}</span>
                            </a>
                        </div>
                        <div class="profile_btn_txt" v-if="draw_info.event_status == 1">
                            Enter after {{ draw_info.start_time && moment(Number(draw_info.start_time)).format('hh:mm A, MM/DD ddd') }}
                        </div>
                        <div class="profile_btn_txt" v-if="draw_info.event_status == 2 || has_joined">
                            Enter before {{ draw_info.end_time && moment(Number(draw_info.end_time)).format('hh:mm A, MM/DD ddd') }}
                        </div>
                    </div>
                    <div class="profile_card share_card">
                        <h2 class="fy_bolder">How it works</h2>
                        <div class="share_info">
                            <div class="share_item">
                                <div class="share_item_img time_icon"></div>
                                <div class="share_item_title fy_bolder">Registration</div>
                                <div class="share_item_txt">
                                    {{ draw_info.start_time && moment(Number(draw_info.start_time)).format('hh:mm A, MM/DD ddd') }}
                                    - 
                                    {{ draw_info.end_time && moment(Number(draw_info.end_time)).format('hh:mm A, MM/DD ddd') }}</div>
                            </div>
                            <div class="share_item">
                                <div class="share_item_img flower_icon"></div>
                                <div class="share_item_title fy_bolder">Draw</div>
                                <div class="share_item_txt">
                                    <span class="ft_puper">{{ prize_info.prize_count }} winners </span>
                                    will be selected on {{ draw_info.result_time && moment(Number(draw_info.result_time)).format('hh:mm A, MM/DD ddd') }}
                                    </div>
                            </div>
                            <div class="share_item">
                                <div class="share_item_img notify_icon"></div>
                                <div class="share_item_title fy_bolder">Notify</div>
                                <div class="share_item_txt">We will notify you with the results once the draw end.</div>
                            </div>
                        </div>
                    </div>
                    <div class="profile_link" v-if="false">
                        <a href="javascript:;">
                            Download Minipass, only at App Store
                        </a>
                    </div>
                    <div class="profile_link">
                        <a href="https://forms.gle/PD47ELmTYygzcUnB6" target="_blank" style="color: #7427ff; font-size: 14px;">
                            List your events on Minipass ->
                        </a>
                    </div>
                </div>
            </div>
            <div class="profile_down">
                <div class="footer_left">
                    <span class="footer_phone"></span>
                    <span class="footer_text ft_puper">
                        Get Minipass
                        <br/>
                        Have a fun meal
                    </span>
                </div>
                <div class="footer_right purple_icon">
                    <a class="down_btn no_icon" @click="fixed_layer_to_store" href="javscript:;">Download</a>
                    <a class="down_btn" href="javascript:;" @click="fixed_layer_to_app">Open app</a>
                </div>
            </div>
        </div>
        <div class="pop" :style="{display: show_verfiy_layer ? 'block' : 'none'}">
            <div class="pop_main invitation" style="top: 16rem;">
                <div class="invite_main">
                    <h2 class="fy_bold" style="font-size: 1rem;">Verify your phone number</h2>
                    <div class="share_input">
                        <div class="input_item">
                            <input value="+1" style="width: 10%;" readonly="readonly"/>
                            <input style="width: 90%;" placeholder="Phone Number" v-model="phone_num" />
                        </div>
                        <div class="input_item input_code">
                            <input placeholder="Code" v-model="code" />
                            <a href="javascript:;" @click="doSendCode">
                                {{ sending_time > 0 ? sending_time + 's' : 'Get code'}}
                            </a>
                        </div>
                        <div class="share_input_error" v-if="err_msg">{{ err_msg || 'Incorrect code' }}</div>
                    </div>
                    <a class="invite_btn puper_btn" href="javascript:;" @click="doLayerJoin">{{ draw_info.event_status == 1 ? 'Notify me' : 'Join' }}</a>
                    <a class="invite_btn" href="javascript:;" @click="doLayerCancel">Cancel</a>
                </div>
            </div>
        </div>
        <div class="pop" :style="{display: draw_layer_show ? 'block' : 'none'}">
            <div class="pop_main invitation" style="top: 16rem;">
                <div class="invite_main">
                    <h2 class="fy_bolder" style="font-size: 1.2rem;">To join the draw, please download Minipass at App store.</h2>
                    <a class="invite_btn puper_btn" href="javascript:;" @click="doLayerDownload">Download</a>
                    <a class="invite_btn" href="javascript:;" @click="doLayerDownloadCancel">Cancel</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import OpenApp from 'web-open-app';
    import Swal from 'sweetalert2';
    import moment from 'moment';

    export default {
        name: 'draw_info',
        components: {
        },
        data() {
            return {
                draw_id: this.$route.params.id || '',
                show_verfiy_layer: false,
                is_wexin: navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1,
                moment,
                phone_num: '',
                code: '',
                sending_time: 0,
                timer: null,
                is_loading: false,
                draw_layer_show: false
            };
        },
        created() {
            if (this.draw_id) {
                this.getDrawInfo(this.draw_id);
            }

            gtag("event", "draw_share_pv", {
                bid: this.draw_id
            });
        },
        watch: {
            err_msg() {
                this.is_loading = false;
            },
            has_joined() {
                if (this.has_joined) {
                    this.doLayerCancel();
                    window.sessionStorage.setItem('has_joined', 1);
                    this.is_loading = false;

                    gtag("event", "draw_join_success", {
                        bid: this.draw_id
                    });
                }
            },
            has_set_notified() {
                if (this.has_set_notified) {
                    this.doLayerCancel();
                    this.is_loading = false;
                }
            },
            draw_info() {
                if (this.draw_info && this.draw_info.id) {
                    // document.title = this.draw_info.event_name;

                    // var metaList = document.getElementsByTagName("meta");
                    // for (var i = 0; i < metaList.length; i++) {
                    //     if (metaList[i].getAttribute("name") == "description") {
                    //         metaList[i].content = `Win the prize with our latest draw feature`;
                    //         continue;
                    //     }

                    //     if (metaList[i].getAttribute("property") == "og:description") {
                    //         metaList[i].content = `Win the prize with our latest draw feature`;
                    //         continue;
                    //     }

                    //     if (metaList[i].getAttribute("property") == "og:title") {
                    //         metaList[i].content = this.draw_info.event_name;
                    //         continue;
                    //     }
                    // }
                }
            }
        },
        mounted() {},
        computed: {
            ...mapGetters({
                draw_info: 'draw/get_draw_info',
                store_info: 'draw/get_store_info',
                prize_info: 'draw/get_prize_info',
                prize_list: 'draw/get_prize_list',
                err_msg: 'draw/get_err_msg',
                has_joined: 'draw/get_has_joined',
                has_set_notified: 'draw/get_has_set_notified'
            }),
            ...mapState({
            }),
            event_status() {
                if (!this.draw_info.id) {
                    return 'Coming Soon';
                }

                if (this.has_joined) {
                    return 'Draw Joined';
                }

                if (this.draw_info.event_status == 0) {
                    return 'Draw closed';
                } else if (this.draw_info.event_status == 1) {
                    return 'Notify me to join';
                } else if (this.draw_info.event_status == 2) {
                    return 'Join the draw' + (this.draw_info.need_pay == 1 ? ' - $' + (this.draw_info.price * 1).toFixed(2) : '');
                } else if (this.draw_info.event_status == 3 || this.draw_info.event_status == 4 || this.draw_info.event_status == 5) {
                    return 'Draw closed';
                } else if (this.draw_info.event_status == 0 && this.draw_info.to_unpublished_time) {
                    return 'Draw closed';
                }

                return 'Coming Soon';
            }
        },
        methods: {
            joinNotify() {
                if (this.has_set_notified) {
                    return;
                }

                this.show_verfiy_layer = true;
            },
            doLayerCancel() {
                this.show_verfiy_layer = false;
                clearInterval(this.timer);
                this.sending_time = 0;
                this.phone_num = '';
                this.code = '';
            },
            async doLayerJoin() {
                if (this.phone_num == '' || this.code == '' || this.is_loading) { return; }
                this.is_loading = true;
                await this.verifyCode({
                    phone_num: '+1 ' + this.phone_num,
                    sid: this.store_info.sid,
                    code: this.code,
                    draw_id: this.draw_id,
                    event_name: this.draw_info.event_name,
                    event_status: this.draw_info.event_status
                });
            },
            async doSendCode() {
                if (!this.phone_num || this.sending_time > 0) { return; }

                await this.sendCode({
                    phone_num: '+1 ' + this.phone_num,
                    sid: this.store_info.sid
                });

                this.sending_time = 60;
                this.timer = setInterval(() => {
                    this.sending_time = this.sending_time - 1;
                    if (this.sending_time == 0) {
                        clearInterval(this.timer);
                    }
                }, 1000);
            },
            joinDraw() {
                let now_time = new Date().getTime();

                if (now_time < this.draw_info.start_time || now_time > this.draw_info.end_time) {
                    this.set_err_mutation('Draw has closed');
                    this.getDrawInfo(this.draw_id);
                    return;
                }

                if (this.draw_info.event_status == 2 && !this.has_joined) {
                    gtag("event", "draw_join_button_click", {
                        bid: this.draw_id
                    });

                    if (this.draw_info.need_pay == 1) {
                        this.draw_layer_show = true;
                        return;
                    }

                    this.show_verfiy_layer = true;
                }
            },
            doLayerDownload() {
                gtag("event", "draw_join_layer_to_store", {
                    bid: this.draw_id
                });
                
                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=drawH5_paid_popup_download&mt=8');
                this.draw_layer_show = false;
            },
            doLayerDownloadCancel() {
                this.draw_layer_show = false;
            },
            ...mapActions({
                getDrawInfo: 'draw/getDrawInfo',
                sendCode: 'draw/sendCode',
                verifyCode: 'draw/verifyCode'
            }),
            ...mapMutations({
                set_err_mutation: 'draw/DRAW_SET_ERROR'
            }),
            fixed_layer_to_store() {
                gtag("event", "draw_fixed_layer_to_store", {
                    bid: this.draw_id
                });
                
                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=draw-H5-banner-download&mt=8');
            },
            fixed_layer_to_app() {
                gtag("event", "draw_fixed_layer_to_app", {
                    bid: this.draw_id
                });
                
                this.do_open_app();
            },
            do_open(_url) {
                window.open(_url || "https://apps.apple.com/cn/app/minipass-minitable/id1626268909?l=en")
            },
            do_open_app() {
                window.location = `https://app.minitable.net/review/?id=` + this.draw_id + '&type=draw';

                setTimeout(() => {
                    this.$nextTick(() => {
                        Swal.fire({
                            title: 'Not installed yet?',
                            showDenyButton: true,
                            showCancelButton: false,
                            confirmButtonText: 'Download',
                            denyButtonText: `Cancel`,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                gtag("event", "draw_popup_to_store", {
                                    bid: this.draw_id
                                });

                                this.do_open('https://apps.apple.com/app/apple-store/id1626268909?pt=124978031&ct=draw-H5-popup-download&mt=8');
                            }
                        })
                    });
                }, 2000);
            },
        }
    };
</script>

<style>
    .join_us {
        padding: 2rem 1rem 0;
        position: relative;
        /* background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 180% auto; */
    }
    .join_us_bg {
        width: 100%;
        height: 25rem;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../assets/images/puper_circle.png');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 160% auto;
        z-index: -1;
    }
    .join_header {
        margin: 0 auto;
        width: 7.5rem;
        height: 1.25rem;
        background-image: url('../assets/images/minipass_white.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: 2rem;
    }
    .join_content{
        background-color: #fff;
        text-align: center;
        border-radius: .75rem;
        padding-top: 0.75rem;
        box-shadow: 0px 8px 5px rgba(230, 230, 230, 0.4);
        /* padding-bottom: 1rem; */
    }
    .profile_main {
        padding: 0 .75rem .75rem;
    }
    .profile_main img {
        border-radius: .75rem;
        display: block;
        width: 100%;
    }
    .profile_main h2 {
        margin: .75rem 0;
        line-height: 1.75rem;
        text-align: left;
    }
    .profile_main p {
        padding-left: 1.25rem;
        text-align: left;
        color: #333;
        background-repeat: no-repeat;
        background-size: 1rem auto;
        background-position: left center;
        line-height: 1.5rem;
    }
    .profile_main .profile_address {
        background-image: url('../assets/images/address_icon.png');
    }
    .profile_main .profile_tel {
        background-image: url('../assets/images/phone_icon.png');
    }
    .profile_card {
        margin-bottom: 1.75rem;
    }
    .profile_card h2 {
        font-size: 1rem;
        color: #000;
    }
    .profile_info {
        text-align: left;
        color: #333;
        line-height: 1.5;
        overflow-wrap: break-word;
    }
    .share_card .profile_info {
        color: #9098a3;
    }
    .share_item {
        text-align: left;
        padding-left: 2rem;
        padding-bottom: 1.5rem;
        margin-bottom: .5rem;
        position: relative;
        background: url('../assets/images/line_icon.png') no-repeat .5rem bottom;
        background-size: .3125rem auto;
    }
    .share_info .share_item:last-child {
        background: none;
        padding-bottom: 0;
    }
    .share_item_img {
        width: 1.3125rem;
        height: 1.3125rem;
        position: absolute;
        left: 0;
        top: 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    .share_item_img.time_icon {
        background-image: url('../assets/images/time_icon.png');
    }
    .share_item_img.notify_icon {
        background-image: url('../assets/images/notify_icon.png');
        background-size: auto 100%;
    }
    .share_item_img.flower_icon {
        background-image: url('../assets/images/flower_icon.png');
    }
    .share_item_txt {
        margin-top: .25rem;
        color: #8F98A3;
    }
    .profile_btn a.no_start {
        background-color: #ba93fe;
    }
    .profile_btn_txt {
        padding-top: 10px;
    }
    .profile_btn {
        margin-top: 1.75rem;
    }
    .profile_btn a {
        display: flex;;
        height: 2.5rem;
        line-height: 2.5rem;
        color: #fff;
        background-color: #7427ff;
        text-align: center;
        border-radius: 1.25rem;
        justify-content: center;
        font-size: .875rem;
        font-family: 'Poppins-SemiBold';
    }
    .profile_btn a i {
        display: inline-block;
        width: 2rem;
        height: 2.5rem;
        background-repeat: no-repeat;
        background-size: 1rem auto;
        background-position: left center;
    }
    .profile_btn a i.link_reseve {
        background-image: url('../assets/images/reseve_icon.png');
    }
    .profile_btn a i.link_wait {
        background-image: url('../assets/images/wait_icon.png');
    }
    .profile_link a {
        color: #9ca4ae;
    }
    .profile_down {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        padding: 0;
        /* max-width: 768px; */
        bottom: 0;
        left: 0;
        background-color: #fff;
        box-shadow: 0px 8px 14px rgba(0,0,0, 0.15);
    }
    .profile_down .footer_left .footer_phone {
        border-radius: 10px;
        box-shadow: 0px 7px 14px rgba(0,0,0, 0.15);
    }
    .profile_down .footer_right{
        width: 16rem;
        display: flex;
        justify-content: space-between;
        transform: scale(.5);
        float: none;
        position: absolute;
        right: -3.5rem;
        top: 1rem;
    }
    .profile_down .footer_right.purple_icon{
        width: 18rem;
        right: -4.25rem;
    }
    .profile_down .down_btn {
        background: #7427FF;
        border: 2px solid #7427FF;
        padding: 0 .25rem;
        text-align: center;
        width: 7.5rem;
        height: 3.25rem;
        line-height: 3.25rem;
        font-size: 1.1875rem;
        box-sizing: border-box;
        margin-top: 0;
        font-family: 'Poppins-SemiBold';
    }
    .profile_down .down_btn.no_icon {
        background: #fff;
        color: #7427FF;
    }
    .profile_down .purple_icon .down_btn.no_icon {
        width: 10rem;
        background-image: url('../assets/images/apple_icon_purple.png');
        background-position: .5rem center;
        background-repeat: no-repeat;
        background-size: auto 50%;
        padding-left: 1.75rem;
    }
    .join_footer {
        margin-top: 1rem;
        width: 100%;
        height: 5rem;
        background-color: #FFF4AE;
        background-image: url('../assets/images/yellow_bg.png');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: auto 100%;
        border-radius: 0.75rem;
        overflow: hidden;
    }
    .footer_left {
        float: left;
        height: 3rem;
        padding: 1rem .625rem;
    }
    .footer_left span {
        display: inline-block;
    }
    .footer_left .footer_phone {
        width: 3rem;
        height: 3rem;
        background-image: url('../assets/images/minipass_phone.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
    }
    .footer_left .footer_text {
        width: 8.5rem;
        float: right;
        margin-left: 0.625rem;
        font-size: 0.75rem;
        font-family: Poppins-Bold;
        margin-top: 0.5rem;
    }
    .profile_down .footer_left .footer_text {
        font-family: 'Poppins-SemiBold';
    }
    .footer_right {
        float: right;
        margin-right: .625rem;
    }
    .down_btn {
        margin-top: 1.5rem;
        display: block;
        width: 4.75rem;
        height: 2rem;
        line-height: 2rem;
        background-color: #7427FF;
        background-image: url('../assets/images/apple_icon.png');
        background-position: .5rem center;
        background-repeat: no-repeat;
        background-size: auto 50%;
        border-radius: 1.5rem;
        color: #fff;
        padding-left: 1.875rem;
    }
    .share_input .input_item {
        padding: 0 .5rem .75rem;
    }
    .share_input {
        padding-bottom: .5rem;
    }
    .share_input input {
        display: block;
        width: 100%;
        border: 0;
        border-bottom: 1px solid #E2E7EE;
        line-height: 1.375rem;
        display: inline-block;
    }
    .share_input .input_code {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0;
    }
    .share_input .input_code input {
        width: 6.625rem;
    }
    .share_input .input_code a {
        display: block;
        width: 5.625rem;
        height: 1.75rem;
        line-height: 1.75rem;
        box-sizing: border-box;
        border: 1px solid #7427FF;
        border-radius: .875rem;
        color: #7427FF;
    }
    .share_input_error {
        color: red;
        text-align: left;
        padding: .25rem .5rem 0;
    }
    .share_img {
        min-height: 330px;
    }
</style>
